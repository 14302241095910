<template lang="pug">
v-card(width="100%")
  v-card-text
    v-select(:items="topic_items", v-model="sel_topic", dense)
  v-card-actions
    v-btn(color="secondary", @click="send()") Send Topic to POS Biz
</template>
<script>
export default {
  data() {
    return {
      sel_topic: null,
      topics: [
        {
          name: "app_update",
          subject: "Selflane POS Updates",
          path: "Biz/POSUpdate",
        },
        {
          name: "gross_receipts_tax",
          subject: "Gross Receipts Tax Report | Selflane",
          path: "Biz/GRT",
        },
      ],
    };
  },
  computed: {
    topic_items() {
      return this.topics.map((o) => o.name);
    },
  },
  methods: {
    async send() {
      if (!this.sel_topic) return;
      const params = { topic_name: this.sel_topic };
      try {
        const { data } = await this.axios.post("/email/mailPOSBiz", params);
        this.$toast.success(data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
