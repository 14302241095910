<template lang="pug">
v-list-item(@click.stop="welcome", :loading="loading")
  v-list-item-title Welcome Email
</template>

<script>
export default {
  props: {
    domain: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async welcome() {
      this.loading = true;
      try {
        const result = await this.$api.b.domain.custom("welcome", {
          domain_id: this.domain._id,
        });
        this.$toast.success(result?.message);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>