<template lang="pug">
v-card(width="100%")
  v-card-text
    BizSelector(@select="select")
  v-card-actions
    v-btn(color="secondary", @click="send()", :loading="loading") Chowly Integration Welcome Email
</template>

<script>
export default {
  data: () => ({
    selectBiz: null,
    loading: false,
  }),
  methods: {
    select(biz) {
      this.selectBiz = biz;
    },
    async send() {
      this.loading = true;
      try {
        if (!this.selectBiz || !this.selectBiz._id) {
          throw new Error("Please select a biz");
        }
        const params = {
          bizId: this.selectBiz._id,
        };
        const { data } = await this.axios.post("/chowly/email/welcome", params);
        this.$toast.success(data.message);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
