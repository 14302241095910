<template lang="pug">
Page(title="Misc")
  .s-vstack
    .sl-title Communication
    .link-box
      MessagePOS
      MessageAllPOS
    .link-box
      SimpleEmail
      SMS
      Call
    v-divider
    .sl-title Integration
    div
      ChowlyIntegration
    v-divider
    .sl-title Marketing
    v-row
      v-col.s-vstack(cols="12", md="6")
        MailBizTopic
        MailOnlineBizTopic
      v-col.s-vstack(cols="12", md="6")
        MailPOSBizTopic
        MailAllOwners
    MarketingEmail
</template>

<script>
import { mapActions } from "vuex";
import Call from "./Call";
import ChowlyIntegration from "./ChowlyIntegration";
import MailAllOwners from "./MailAllOwners";
import MailBizTopic from "./MailBizTopic";
import MailOnlineBizTopic from "./MailOnlineBizTopic";
import MailPOSBizTopic from "./MailPOSBizTopic";
import MarketingEmail from "./MarketingEmail";
import MessageAllPOS from "./MessageAllPOS";
import MessagePOS from "./MessagePOS";
import SimpleEmail from "./SimpleEmail";
import SMS from "./SMS";

export default {
  components: {
    MessagePOS,
    MessageAllPOS,
    SimpleEmail,
    SMS,
    Call,
    ChowlyIntegration,
    MailBizTopic,
    MailOnlineBizTopic,
    MailPOSBizTopic,
    MailAllOwners,
    MarketingEmail,
  },
  data() {
    return { bizs: [] };
  },
  mounted() {
    this.loadBizs();
    this.loadSample();
  },
  methods: {
    ...mapActions(["setEmailSamples"]),
    async loadSample() {
      const params = { criteria: {}, select: "subject content_short" };
      const result = await this.$api.corp.sampleEmail.list(params);
      this.setEmailSamples(result);
    },
    async loadBizs() {
      const params = { criteria: {}, select: "name" };
      const result = await this.$api.biz.list(params);
      this.bizs = result.toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>
