<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center(v-if="pause_time")
    v-btn.mr-3.text-capitalize(
      outlined,
      color="success",
      small,
      @click="resume",
      :loading="resuming"
    ) Resume Now
    span.subtitle-2 Resume {{ pause_time }}.
    v-btn(icon, small, @click="open", color="secondary")
      v-icon(small) mdi-pencil
  div(v-else)
    v-btn.text-capitalize(outlined, color="secondary", small, @click="open") Pause Online Ordering
  v-dialog(v-model="dialog", max-width="500")
    v-card
      v-card-title Pause online ordering
      v-form
        v-card-text
          v-select(
            v-model="value",
            :items="items",
            label="Pause for",
            outlined,
            dense,
            color="secondary"
          )
          v-btn(block, color="secondary", @click="pause()", :loading="loading") Pause
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";

export default {
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      value: 30,
      loading: false,
      resuming: false,
    };
  },
  computed: {
    items() {
      return _.map(_.range(10, 101, 10), (i) => ({
        text: `${i} minutes`,
        value: i,
      }));
    },
    pause_time() {
      const next_open = this.biz?.schedule?.next_open;
      if (!next_open) return null;
      const isFuture = moment(next_open).isAfter(moment());
      if (!isFuture) return null;
      return moment(next_open).fromNow();
    },
  },
  methods: {
    open() {
      this.value = 30;
      this.dialog = true;
    },
    async pause() {
      if (!this.biz) return;
      this.loading = true;
      const next_open = moment().add(this.value, "minutes").valueOf();
      try {
        const action = { $set: { "schedule.next_open": next_open } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.dialog = false;
        this.$toast.success("Online ordering paused");
        this.$emit("updated", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to pause");
      }
      this.loading = false;
    },
    async resume() {
      if (!this.biz) return;
      this.resuming = true;
      try {
        const action = { $unset: { "schedule.next_open": "" } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$toast.success("Online ordering resumed");
        this.$emit("updated", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to resume");
      }
      this.resuming = false;
    },
  },
};
</script>