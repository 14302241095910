<template lang="pug">
div(v-if="biz")
  Form(ref="form")
  .section-cell(@click="open")
    .section-title Email
    .section-content {{ biz.email }}
    .section-note(v-if="biz.carboncopy") CC Recipients: {{ biz.carboncopy }}
    .section-note
      v-icon.mr-2(:color="color", small) {{ icon }}
      span {{ text }}
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    email_online() {
      return this.biz.settings?.email_online == true;
    },
    color() {
      return this.email_online ? "green" : "#6b7280";
    },
    icon() {
      return this.email_online ? "mdi-check" : "mdi-close";
    },
    text() {
      if (this.email_online) return "Send email on online orders";
      return "Do not send email on online orders";
    },
  },
  methods: {
    open() {
      this.$refs.form.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.section-cell
  display: flex
  flex-direction: column
  cursor: pointer
  &:hover
    .section-title
      color: #3b82f6
.section-title
  font-size: 0.8rem
  font-weight: 500
  margin-bottom: 0.5rem
  color: #6b7280
  cursor: pointer
.section-content
  font-size: 1rem
  font-weight: 500
  color: #111827
.section-note
  font-size: 0.8rem
  color: #6b7280
</style>
