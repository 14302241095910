<template lang="pug">
div(v-if="biz?.address")
  Form(ref="form")
  .section-cell(@click="open")
    .section-title Address
    .section-content {{ biz.address | address("postal_code") }}
    .section-note(v-if="biz.address.note") Note: {{ biz.address.note }}
    .section-note Timezone: {{ biz.address.timezone }}
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  name: "BizAddress",
  components: { Form },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      this.$refs.form.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.section-cell
  display: flex
  flex-direction: column
  cursor: pointer
  &:hover
    .section-title
      color: #3b82f6
.section-title
  font-size: 0.8rem
  font-weight: 500
  margin-bottom: 0.5rem
  color: #6b7280
  cursor: pointer
.section-content
  font-size: 1rem
  font-weight: 500
  color: #111827
.section-note
  font-size: 0.8rem
  color: #6b7280
</style>
