<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Business Phone
      v-card-text
        PhoneField(v-model="number")
        v-switch(
          v-model="call_online",
          label="Call business phone on online orders",
          color="secondary"
        )
        div We deduct 10¢ from the payment as the fee for the phone service if the calling is enabled.
      v-card-actions
        v-btn(block, type="submit", color="secondary", :loading="loading") save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      number: "",
      call_online: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    phone() {
      if (this.biz?.phones?.length) return this.biz.phones[0].number;
      else return null;
    },
  },
  methods: {
    open() {
      this.number = this.phone;
      this.call_online = this.biz.settings?.call_online == true;
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const phones = [{ number: this.number, countryCode: "1" }];
      try {
        const action = {
          $set: {
            phones,
            "settings.call_online": this.call_online,
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
