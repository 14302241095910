<template lang="pug">
div
  .my-container
    span Today:
    DayHours(:schedule="biz.schedule")
  button.delay-btn(@click="open", role="button") Open Delay {{ open_delay }}'
    v-icon.ml-1(x-small) mdi-pencil
  v-dialog(v-model="dialog", width="400")
    v-card
      v-toolbar(dense, flat)
        .subtitle-2 Edit Open Delay
      v-divider
      v-form(@submit.prevent="submit")
        v-card-text
          v-select(
            v-model="value",
            :items="items",
            label="Delay time",
            menu-props="auto"
          )
          .caption.text--secondary {{ instruction }}
        v-card-actions
          v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      value: 30,
      items: [
        { text: "0 minutes", value: 0 },
        { text: "5 minutes", value: 5 },
        { text: "10 minutes", value: 10 },
        { text: "15 minutes", value: 15 },
        { text: "20 minutes", value: 20 },
        { text: "25 minutes", value: 25 },
        { text: "30 minutes", value: 30 },
        { text: "45 minutes", value: 45 },
        { text: "60 minutes", value: 60 },
      ],
      instruction:
        "e.g., a business opens at 10:00, but it can delay the first online order to 10:30 if delay time is 30",
    };
  },
  computed: {
    open_delay() {
      return this.biz?.schedule?.open_delay || 0;
    },
  },
  methods: {
    open() {
      this.value = this.open_delay;
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const action = { $set: { "schedule.open_delay": this.value } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.my-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  align-items: center
  gap: 12px
  font-size: 1rem
  font-weight: 600
  color: #334155
.delay-btn
  cursor: pointer
  color: #6b7280
  font-weight: 500
  font-size: 0.8rem
  // hover color change
  &:hover
    color: #3b82f6
    text-decoration: underline
</style>
