<template lang="pug">
v-card(width="100%")
  v-card-text
    BizSelector(@select="select")
    v-select(
      :items="topics",
      item-text="subject",
      item-value="name",
      v-model="sel_topic",
      dense
    )
  v-card-actions
    v-btn(color="secondary", @click="send()") Send Business Special Topic
</template>

<script>
export default {
  data: () => ({
    bizs: [],
    selectBiz: null,
    sel_topic: null,
    topics: [
      {
        name: "member_self_enroll",
        subject: "Member Self Enrollment",
        path: "Member/SignUpBizIntro",
      },
      {
        name: "biz_bank_account",
        subject: "Manage Bank Account",
        path: "Biz/BankAccount",
      },
      {
        name: "biz_payment_account",
        subject: "Manage Payments Account",
        path: "Biz/PaymentAccount",
      },
      {
        name: "legalities",
        subject: "Missing Legalities for Online Ordering",
        path: "Biz/Legalities",
      },
      {
        name: "annoucement",
        subject: "End of Fax Service | Selflane",
        path: "Biz/Announcement",
      },
    ],
  }),
  computed: {
    topic_items() {
      return this.topics.map((t) => t.name);
    },
  },
  methods: {
    select(biz) {
      this.selectBiz = biz;
    },
    async send() {
      if (!this.selectBiz || !this.selectBiz._id) {
        return this.$toast.error("Please select a biz");
      }
      const params = {
        bizId: this.selectBiz._id,
        topic_name: this.sel_topic,
      };
      try {
        const { data } = await this.axios.post("/email/mailBiz", params);
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
