<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Email Contacts
      v-card-text
        v-text-field(label="Main Email Contact", v-model="email")
        v-text-field(
          label="CC Recipients",
          v-model="carboncopy",
          hint="Extra recipients for online orders. Separate with comma.",
          placeholder="a@gmail.com, b@gmail.com",
          persistent-hint
        )
        v-switch(
          v-model="email_online",
          label="Send email on online orders",
          color="secondary"
        )
        div Email will be sent to the main email contact and CC recipients when there is an online order. No cost for this service. If the email is redundant with the POS usage, please turn off the service.
      v-card-actions
        v-btn(block, type="submit", color="secondary", :loading="loading") save
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      email: "",
      carboncopy: "",
      email_online: false,
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      this.email = this.biz.email;
      this.carboncopy = this.biz.carboncopy;
      this.email_online = this.biz.settings?.email_online == true;
      this.dialog = true;
    },
    cleanCarbonCopy() {
      if (!this.carboncopy) return;
      this.carboncopy = this.carboncopy.trim();
      let items = this.carboncopy.split(",");
      items = _.chain(items)
        .map((o) => o.toLowerCase().trim())
        .filter((o) => this.validateEmail(o))
        .value();
      this.carboncopy = items.join(",");
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      try {
        this.cleanCarbonCopy();
        const email = this.email.toLowerCase().trim();
        if (!this.validateEmail(email)) {
          throw new Error("Invalid email address");
        }
        const action = {
          $set: {
            email,
            carboncopy: this.carboncopy,
            "settings.email_online": this.email_online,
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
