<template lang="pug">
.s-vstack(v-if="biz")
  Pause(:biz="biz", @updated="updated")
  v-row(wrap)
    v-col(cols="12", md="6")
      RegularHours(:biz="biz", @updated="updated")
    v-col(cols="12", md="6")
      .s-vstack
        SpecialHours(:biz="biz", @updated="updated")
        TemporaryClose(:biz="biz", @updated="updated")
</template>

<script>
import Pause from "./Pause";
import RegularHours from "./RegularHours/index";
import SpecialHours from "./SpecialHours/index";
import TemporaryClose from "./TemporaryClose";
import Today from "./Today";

export default {
  components: { RegularHours, SpecialHours, Pause, TemporaryClose, Today },
  props: {
    biz: { type: Object, required: true },
  },
  methods: {
    updated($event) {
      this.$emit("updated", $event);
    },
  },
};
</script>
