<template lang="pug">
simple-table
  thead
    tr
      th
      th Jan
      th Feb
      th Mar
      th Apr
      th May
      th Jun
      th Jul
      th Aug
      th Sep
      th Oct
      th Nov
      th Dec
      th Total
  tbody
    tr(v-for="(group, index) in groups", :key="index")
      th {{ group.title }}
      td(v-for="(item, mindex) in group.months", :key="mindex") {{ item.total | currency }}
      td {{ group.total | currency }}
</template>

<script>
export default {
  props: {
    groups: { type: Array, default: () => [] },
  },
};
</script>