<template lang="pug">
v-menu(
  v-model="menu",
  :nudge-right="40",
  transition="scale-transition",
  offset-y
)
  template(v-slot:activator="{ on }")
    .overlap-btn-container
      v-select(
        v-model="industry",
        label="Place Type",
        :items="industryOptions",
        readonly
      )
        v-icon(right) mdi-chevron-down
      button.overlap-btn(v-on="on", type="button")
  v-list
    v-list-item-group(
      v-model="industry",
      v-for="(group, index) in placeTypeGroups",
      :key="index"
    )
      v-subheader {{ group.title }}
      v-list-item(
        v-for="(item, index) in group.items",
        :key="index",
        :value="item.value"
      )
        v-list-item-title {{ item.text }}
</template>

<script>
export default {
  props: {
    value: { type: Number, required: true },
  },
  data() {
    return {
      menu: false,
      industry: this.value,
      placeTypeGroups: [
        {
          title: "Food & Drink",
          items: [
            { text: "Restaurant", value: 0 },
            { text: "Food Truck", value: 1 },
            { text: "Catering Service", value: 2 },
            { text: "Commercial Kitchen", value: 3 },
            { text: "Virtual Kitchen", value: 4 },
          ],
        },
        {
          title: "Beauty & Health",
          items: [
            { text: "Nail Salon", value: 100 },
            { text: "Hair Salon", value: 101 },
          ],
        },
        {
          title: "Auto Industry",
          items: [{ text: "Car Wash", value: 200 }],
        },
        {
          title: "Home Improvement",
          items: [{ text: "Nursery", value: 300 }],
        },
        {
          title: "Retail",
          items: [{ text: "Retail", value: 400 }],
        },
      ],
    };
  },
  computed: {
    industryOptions() {
      return this.placeTypeGroups.reduce((acc, group) => {
        return acc.concat(group.items);
      }, []);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.industry = value;
      },
    },
    industry(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="sass" scoped>
.overlap-btn-container
  position: relative
  display: inline-block

.overlap-btn
  position: absolute
  right: 0
  top: 0
  left: 0
  bottom: 0
  cursor: pointer
</style>