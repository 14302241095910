<template lang="pug">
.s-vstack
  div
    YearSelector(@select="load")
  //- Table.my-2(:items="items")
  Summary(:items="items")
  StripeTerminal(:items="items")
  Rebuild(:year="year")
  div
    v-btn(@click="load()", :loading="loading", small) Load
</template>

<script>
import Rebuild from "./Rebuild/index";
import StripeTerminal from "./StripeTerminal";
import Summary from "./Summary/index";
import Table from "./Table/index.vue";

export default {
  components: { Table, Summary, StripeTerminal, Rebuild },
  data() {
    return {
      items: [],
      year: null,
      loading: false,
    };
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      if (!this.year) return;
      this.loading = true;
      const begin = this.year * 100;
      const end = (this.year + 1) * 100;
      this.items = await this.$api.transaction.stripe.custom("listByMonth", {
        criteria: { month: { $gte: begin, $lt: end } },
      });
      this.loading = false;
    },
  },
};
</script>