<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-card-title Basic Profile
      v-card-text
        v-row(dense)
          v-col(cols="6")
            PriceLevelPicker(v-model="price_range")
          v-col(cols="6")
            IndustrySelector(v-model="industry")
        CatesSelector(v-model="cates", v-if="industry < 100")
        v-text-field(
          label="Website",
          v-model="website",
          @blur="$v.website.$touch()",
          :error-messages="websiteErrors"
        )
        v-textarea(
          label="Description",
          v-model="description",
          rows="4",
          @blur="$v.description.$touch()",
          :error-messages="descriptionErrors",
          counter="1000"
        )
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Save
</template>

<script>
import _ from "underscore";
import { validationMixin } from "vuelidate";
import { maxLength, url } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import CatesSelector from "./CatesSelector";
import IndustrySelector from "./IndustrySelector";

export default {
  components: { CatesSelector, IndustrySelector },
  mixins: [validationMixin],
  validations: {
    website: { url },
    description: { maxLength: maxLength(1000) },
  },
  data() {
    return {
      dialog: false,
      price_range: 0,
      website: "",
      industry: 0,
      cates: [],
      description: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    websiteErrors() {
      const errors = [];
      if (!this.$v.website.$dirty) return errors;
      !this.$v.website.url &&
        errors.push("Invalid website address. Should be http://example.com");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.maxLength && errors.push("Max length of 500");
      return errors;
    },
  },
  methods: {
    open() {
      if (!this.biz) return;
      this.dialog = true;
      this.price_range = this.biz.price_range;
      this.website = this.biz.website;
      this.industry = this.biz.industry;
      this.cates = JSON.parse(JSON.stringify(this.biz.cates));
      this.description = this.biz.description;
      this.$v.$reset();
    },
    validate() {
      // sort cates by value in integer then map to string
      if (!this.cates?.length) throw new Error("Category is required");
      if (this.cates.length > 4)
        throw new Error("Maximum 4 categories are allowed");
      this.cates = _.chain(this.cates)
        .map((cate) => parseInt(cate))
        .sortBy((cate) => cate)
        .map((cate) => `${cate}`)
        .value();
      if (!this.price_range) throw new Error("Price Level is required");
      this.description = this.description?.trim();
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      try {
        this.validate();
        const action = {
          $set: {
            price_range: this.price_range,
            website: this.website,
            industry: this.industry,
            cates: this.cates,
            description: this.description,
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.dialog = false;
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
