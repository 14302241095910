<template lang="pug">
.s-vstack
  BizSelector(@select="selectBiz")
  v-data-table(:headers="headers", :items="locations")
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.address="{ item }")
      span {{ item.address | address }}
    template(v-slot:item.action="{ item }")
      v-btn(icon, small, @click="handleUpdate(item)")
        v-icon(small) mdi-pencil
  div
    v-btn(@click="handleCreate()", v-if="biz", small, color="secondary") Create Location
  CreateLocation(ref="create")
  UpdateLocation(ref="update", @updated="load")
</template>

<script>
import CreateLocation from "./CreateLocation";
import UpdateLocation from "./UpdateLocation";

export default {
  components: { CreateLocation, UpdateLocation },
  data() {
    return {
      biz: null,
      locations: [],
      headers: [
        { text: "Created", value: "created" },
        { text: "Name", value: "display_name" },
        { text: "ID", value: "terminal_id" },
        { text: "Address", value: "address" },
        { text: "Live", value: "livemode", align: "end" },
        { text: "Action", value: "action", align: "end" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    selectBiz(item) {
      this.biz = item;
      this.load();
    },
    handleCreate() {
      if (!this.biz) return this.$toast.error("Please select a biz first");
      this.$refs.create.open(this.biz);
    },
    handleUpdate(item) {
      if (!item) return;
      this.$refs.update.open(item);
    },
    async load() {
      if (!this.biz) return;
      const params = { bizId: this.biz._id };
      this.locations = await this.$api.stripe.terminalLocation.list(params);
    },
  },
};
</script>