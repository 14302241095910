<template lang="pug">
v-btn(icon, small, color="secondary", @click="submit", :loading="loading")
  v-icon(small) mdi-sync
</template>

<script>
import moment from "moment-timezone";
export default {
  props: ["bizId"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      const date = moment().format("YYYY-MM-DD");
      const params = { bizId: this.bizId, date };
      try {
        await this.axios.post("/bizOps/recreate", params);
        this.$emit("done");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>