<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    v-select(:items="select_items", v-model="selection")
  .s-vstack
    div(v-for="item in byCurrency", :key="item.currency")
      h3.px-3 {{ item.currency }}
      Table(:groups="item.groups")
</template>

<script>
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  props: ["items"],
  data() {
    return {
      selection: "compact",
      select_items: ["compact", "complete"],
    };
  },
  computed: {
    byCurrency() {
      const stripeCate = this.$options.filters.stripeCate;
      return _.chain(this.items)
        .groupBy("currency")
        .map((items, k) => {
          const currency = k.toUpperCase();
          const groups = _.chain(items)
            .map((o) => {
              const title = stripeCate(o.category);
              o.title = title;
              return o;
            })
            .groupBy("title")
            .map((items, k) => {
              const months = _.map(_.range(1, 13, 1), (month) => {
                const filtered = items.filter((o) => o.month % 100 == month);
                const total = filtered.reduce(
                  (acc, cur) => acc + cur.selflane_net,
                  0
                );
                return { month: month, total: total };
              });
              const total = months.reduce((acc, cur) => acc + cur.total, 0);
              return { title: k, months: months, total: total };
            })
            .sortBy("title")
            .value();
          return { currency: currency, groups: groups };
        })
        .sortBy("currency")
        .value();
    },
    groups() {
      const stripeCate = this.$options.filters.stripeCate;
      return _.chain(this.items)
        .map((o) => {
          let title = stripeCate(o.category);
          // let title = o.category || "unknown";
          // if (this.selection == "compact") {
          //   if (title.includes("_sldelivery")) {
          //     title = "selflane delivery";
          //   } else if (title.includes("_catering")) {
          //     title = "catering";
          //   } else if (title == "org_invoice") {
          //     title = "invoice";
          //   } else if (title.startsWith("online")) {
          //     title = "online";
          //   }
          // }
          // title = title.replace(/_/g, " ");
          o.title = o.currency + "/" + title;
          return o;
        })
        .groupBy("title")
        .map((items, k) => {
          const months = _.map(_.range(1, 13, 1), (month) => {
            const filtered = items.filter((o) => o.month % 100 == month);
            const total = filtered.reduce(
              (acc, cur) => acc + cur.selflane_net,
              0
            );
            return { month: month, total: total };
          });
          const total = months.reduce((acc, cur) => acc + cur.total, 0);
          return { title: k, months: months, total: total };
        })
        .sortBy("title")
        .value();
    },
  },
};
</script>