<template lang="pug">
v-select(
  label="Categories",
  v-model="content",
  multiple,
  :items="sortedItems",
  dense,
  chips,
  clearable
)
</template>

<script>
import _ from "underscore";

export default {
  name: "CatesSelector",
  props: {
    value: { type: Array, required: true },
  },
  data() {
    return {
      content: this.value,
      items: [
        { name: "American", value: 1 },
        { name: "Chinese", value: 2 },
        { name: "French", value: 3 },
        { name: "Japanese", value: 4 },
        { name: "Korean", value: 5 },
        { name: "Greek", value: 6 },
        { name: "Indian", value: 7 },
        { name: "Italian", value: 8 },
        { name: "Mexican", value: 9 },
        { name: "Thai", value: 10 },
        { name: "Vietnamese", value: 11 },
        { name: "Brazilian", value: 12 },
        { name: "Cajun/Creole", value: 13 },
        { name: "Caribbean", value: 14 },
        { name: "Cuban", value: 15 },
        { name: "Greek and Mediterranean", value: 16 },
        { name: "Hawaiian", value: 17 },
        { name: "Brewpub", value: 18 },
        { name: "Vegan", value: 19 },
        { name: "Peruvian", value: 20 },
        { name: "Asian Fusion", value: 21 },
        { name: "Ice Cream/Dessert", value: 22 },
        { name: "Spanish", value: 23 },
        { name: "New Mexican", value: 24 },
        { name: "Canadian", value: 25 },
        { name: "Coffeehouse", value: 26 },
        { name: "Kosher", value: 27 },
        { name: "Barbecue", value: 28 },
        { name: "Fine Dining", value: 29 },
        { name: "Pizza Place", value: 30 },
        { name: "Seafood", value: 31 },
        { name: "Steakhouse", value: 32 },
        { name: "Middle Eastern", value: 33 },
        { name: "Ethiopian", value: 34 },
        { name: "Moroccan", value: 35 },
        { name: "Buffet", value: 36 },
        { name: "Deli/Sandwich", value: 37 },
        { name: "Bakery", value: 38 },
        { name: "Halal", value: 39 },
        { name: "Organic", value: 40 },
        { name: "Farm-to-Table", value: 41 },
        { name: "Boba Tea", value: 42 },
        { name: "Other", value: 9999 },
      ],
    };
  },
  computed: {
    sortedItems() {
      return _.chain(this.items)
        .map((item) => ({ text: item.name, value: `${item.value}` }))
        .sortBy((item) => item.text)
        .value();
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.content = this.value;
      },
    },
    content: {
      deep: true,
      handler() {
        this.$emit("input", this.content);
      },
    },
  },
};
</script>