<template lang="pug">
div(v-if="biz")
  Form(ref="form")
  .s-vstack
    .section-container(@click="open")
      .section-cell
        .section-title Price Level
        .section-content {{ biz.price_range | price_level }}
      .section-cell
        .section-title Place Type
        .section-content {{ biz.industry | industry }}
      .section-cell(v-if="biz.industry < 100")
        .section-title Categories
        .section-content {{ biz.cates | cates }}
    .section-cell
      .section-title(@click="open") Website
      .d-flex.flex-row.align-center(v-if="biz.website")
        .mr-2 {{ biz.website }}
        a(:href="biz.website", target="_blank")
          v-icon(small, color="secondary") mdi-open-in-new
    .section-cell
      .section-title(@click="open") Description
      .section-note {{ biz.description }}
</template>

<script>
import Form from "./Form";

export default {
  components: { Form },
  props: { biz: { type: Object, required: true } },
  methods: {
    open() {
      this.$refs.form.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.section-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  column-gap: 3rem
  row-gap: 1rem
  cursor: pointer
  &:hover
    .section-title
      color: #3b82f6
.section-cell
  display: flex
  flex-direction: column
.section-title
  font-size: 0.8rem
  font-weight: 500
  margin-bottom: 0.5rem
  color: #6b7280
  cursor: pointer
  &:hover
    color: #3b82f6
.section-content
  font-size: 1.1rem
  font-weight: 600
  color: #111827
.section-note
  font-size: 0.8rem
  color: #6b7280
</style>
