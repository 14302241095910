<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Edit Special Hours
      v-spacer
      v-btn-toggle(v-model="selection", flat, dense, small, color="secondary")
        v-btn(small, :value="'multiple'") Multiple
        v-btn(small, :value="'range'") Range
    v-divider
    v-form(@submit.prevent="submit")
      v-card-text
        v-date-picker(
          v-model="dates",
          :range="selection === 'range'",
          :multiple="selection === 'multiple'",
          :min="minDate",
          color="secondary",
          full-width,
          scrollable,
          elevation="2"
        )
        .subtitle-2.my-2 {{ message }}
        v-radio-group(v-model="status", row)
          v-radio(:value="false", label="Closed", color="error")
          v-radio(:value="true", label="Open", color="secondary")
        RangesSelector(v-if="status", v-model="ranges")
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!valid"
        ) Save
</template>

<script>
import moment from "moment-timezone";
import RangesSelector from "./../RangesSelector";
import BizUtils from "/libs/utils/biz.js";

export default {
  components: { RangesSelector },
  props: { biz: { type: Object, default: () => ({}) } },
  data() {
    return {
      selection: "multiple", // 'range' or 'multiple'
      dialog: false,
      dates: [],
      status: false,
      ranges: [],
      minDate: moment().format("YYYY-MM-DD"),
      loading: false,
    };
  },
  computed: {
    message() {
      if (!this.dates?.length) return "Please select date";
      if (this.dates.length == 1) return this.dates[0];
      const items = this.dates.toSorted((a, b) => a.localeCompare(b));
      if (this.selection == "multiple") {
        return items.join(", ");
      } else {
        return `From ${items[0]} to ${items[items.length - 1]}`;
      }
    },
    valid() {
      if (this.status && !this.ranges?.length) return false;
      return !!this.dates?.length;
    },
  },
  methods: {
    open(data) {
      if (!this.biz) return;
      if (data) {
        const day = JSON.parse(JSON.stringify(data));
        this.dates = [moment(day.date, "YYYY/M/D").format("YYYY-MM-DD")];
        this.status = day.status;
        this.ranges = day.schedule;
      } else {
        this.dates = [];
        this.status = false;
        this.ranges = [{ range: [480, 1200] }];
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      if (!this.dates?.length) return this.$toast.error("Please select date");
      // order ranges by start time and ensure they are valid
      // ranges must have 2 elements and start time must be less than end time
      // ranges should not overlap
      let ranges = BizUtils.prepareRanges(this.ranges);
      if (this.status) {
        try {
          BizUtils.validateRanges(ranges);
        } catch (e) {
          return this.$toast.error(e.message);
        }
      }
      let items = [];
      // if use range, expand dates to all days in the range
      if (this.selection === "range") {
        const start = moment(this.dates[0], "YYYY-MM-DD");
        const end = moment(this.dates[this.dates.length - 1], "YYYY-MM-DD");
        for (var m = start; m.diff(end, "days") <= 0; m.add(1, "days")) {
          items.push(m.format("YYYY-MM-DD"));
        }
      } else {
        items = this.dates.toSorted((a, b) => a.localeCompare(b));
      }
      // at this point, date is formatted as YYYY-MM-DD
      items = items.map((d) => {
        return { date: d, status: this.status, schedule: ranges };
      });
      // compare with specialdays and insert if not found
      const today = moment().startOf("days");
      const specialdays = this.biz.schedule?.specialdays || [];
      for (const s of specialdays) {
        const formatted = moment(s.date, "YYYY/M/D").format("YYYY-MM-DD");
        // skip past date
        if (moment(formatted, "YYYY-MM-DD").isBefore(today)) continue;

        // if is not in the items, add it
        if (!items.find((item) => item.date == formatted)) {
          items.push({
            date: formatted,
            status: s.status,
            schedule: s.schedule,
          });
        }
      }
      // reformat date to YYYY/M/D
      items = items.map((item) => {
        return {
          date: moment(item.date, "YYYY-MM-DD").format("YYYY/M/D"),
          status: item.status,
          schedule: item.schedule,
        };
      });
      this.loading = true;
      try {
        const action = { $set: { "schedule.specialdays": items } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$emit("updated", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update special hours");
      }
      this.loading = false;
    },
  },
};
</script>
